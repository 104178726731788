<template>
  <div>
    <!-- text=".." chi duoc gan trong slot khong co name -->
    <slot text="Hello from child!" >An empty slot here</slot>
    <slot name="header" />

    <ul>
      <li
        v-for="(todo, index) in todos"
        :key="index"
      >
        <slot :todo="todo">
          {{ todo.text }}
        </slot>
      </li>
    </ul>
  </div>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  props: ['todos'],
})
</script>
