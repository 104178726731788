<template>
  <div>
    <CompSlot />
    <!-- <CompSlot>case 2</CompSlot> -->

    <!-- <CompSlot>
      <h2 style="color: red" slot="header">Yolo !!! header slot here</h2>
    </CompSlot> -->

    <!-- <CompSlot>
      <p slot-scope="props" v-text="props.text"></p>
    </CompSlot>

    <CompSlot>
      <p slot-scope="{ text }" v-text="text"></p>
    </CompSlot> -->
    <CompSlot :todos="['Hi Herry', 'Hi Bray']">
      <template slot-scope="{ todo }">
        <span v-if="todo">✓</span>
        {{ todo }}
      </template>
    </CompSlot>

  </div>
</template>
<script>
import CompSlot from './components/slot.vue'
export default {
  components: {
    CompSlot,
  }
}
</script>